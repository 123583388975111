import React, { useState, useEffect, useCallback } from "react"
import { Link } from "gatsby"
import { rem } from "polished"

import PropTypes from "prop-types"
import { Scrollchor, easeOutQuad } from "react-scrollchor"
import {
  AppBar,
  Box,
  Container,
  IconButton,
  Toolbar,
  Drawer,
  ListItem,
  List,
  useMediaQuery,
} from "@mui/material"
import { useTheme } from "@mui/material/styles"
import MenuIcon from "@mui/icons-material/Menu"

import WebLogo from "../../images/web-logo.svg"

import { StyledNavElement } from "./Navigation.styled"

const Navigation = () => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"))
  const [yPos, setYPos] = useState(null)
  const [appBarWhite, setAppBarWhite] = useState(false)
  const [drawerIsOpen, setDrawerIsOpen] = useState(false)
  // const isScrolling = useScrollTrigger

  const openDrawer = () => {
    setDrawerIsOpen(true)
  }

  const handleScroll = useCallback(() => {
    const yAxis = window.pageYOffset
    setYPos(yAxis)
  }, [])

  useEffect(() => {
    yPos > 10 ? setAppBarWhite(true) : setAppBarWhite(false)
  }, [yPos])

  useEffect(() => {
    // Update the document title using the browser API
    window.addEventListener("scroll", handleScroll)
    return () => {
      if (typeof window !== `undefined`) {
        window.removeEventListener("scroll", handleScroll)
      }
    }
  }, [handleScroll])

  return (
    <>
      <AppBar
        position="fixed"
        color="transparent"
        elevation={0}
        sx={{
          backgroundColor: appBarWhite ? "white" : "initial",
          transition: "all 500ms",
        }}
      >
        <Container disableGutters>
          <Toolbar>
            <Box
              sx={{
                flexGrow: {
                  xs: 1,
                  md: 3,
                },
                display: "flex",
                justifyContent: {
                  xs: "flex-start",
                  md: "flex-start",
                },
              }}
            >
              <Link to="/#home">
                <Box
                  component="img"
                  src={WebLogo}
                  alt="logo preferred networking"
                  sx={{ py: 2, width: isSmall ? rem(150) : rem(260) }}
                />
              </Link>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: {
                  xs: "none",
                  sm: "none",
                  md: "block",
                  lg: "block",
                  xl: "block",
                },
              }}
            >
              <Box>
                <Box display="flex" justifyContent="space-between"></Box>
                {/* <SocialLinks /> */}
              </Box>
            </Box>
            <Box
              sx={{
                display: {
                  xs: "flex",
                  sm: "flex",
                  md: "none",
                  lg: "none",
                },
              }}
            >
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={openDrawer}
              >
                <MenuIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer
        anchor="right"
        open={drawerIsOpen}
        onClose={() => setDrawerIsOpen(false)}
      >
        <List></List>
      </Drawer>
    </>
  )
}

Navigation.propTypes = {
  children: PropTypes.node,
}

export default Navigation
