import React from "react"
import { rem } from "polished"
import { Link } from "gatsby"
import cuid from "cuid"
import PropTypes from "prop-types"
import {
  Container,
  Box,
  Grid,
  Typography,
  Button,
  useMediaQuery,
} from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { SocialLinks } from "../SocialLinks"
import { StyledFooter } from "./Footer.styled"

import WebLogoWhite from "../../images/web-logo-white.svg"

import {useSiteData} from "../../hooks/useSiteData"

const footerLinks = [
  {
    id: cuid(),
    title: "Terms & Conditions",
    route: "/terms-conditions",
  },
  {
    id: cuid(),
    title: "Privacy Policy",
    route: "/privacy-policy",
  },
]

const Footer = ({ children }) => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"))
  const year = new Date()
  return (
    <StyledFooter>
      <Container disableGutters>
        <Box sx={{ px: isSmall ? 2 : 0 }}>
          <Grid container>
            <Grid item xs={12} md={7}>
              <Box
                display="flex"
                justifyContent={isSmall ? "center" : "flex-start"}
                sx={{ pt: isSmall ? `${rem(32)}` : "initial" }}
              >
                <Box
                  component="img"
                  src={useSiteData().siteLogo2.localFile.publicURL}
                  alt="web logo white"
                  sx={{
                    width: `${rem(180)}`,
                    mb: isSmall ? `${rem(32)}` : "initial",
                  }}
                />
              </Box>
            </Grid>
            {!isSmall && (
              <Grid item xs={12} md={5}>
                <Box display="flex" justifyContent="flex-end">
                  <SocialLinks white />
                </Box>
              </Grid>
            )}
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Box
                sx={{
                  height: "36px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography align="center" color={theme.palette.common.white}>
                  {useSiteData().copyrightText}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </StyledFooter>
  )
}

Footer.propTypes = {
  children: PropTypes.node,
}

export default Footer
