import React, { useState, useEffect, useCallback } from "react"
import { rem } from "polished"

import PropTypes from "prop-types"
import { Scrollchor, easeOutQuad } from "react-scrollchor"
import {
  AppBar,
  Box,
  Container,
  IconButton,
  Toolbar,
  Drawer,
  ListItem,
  List,
  useMediaQuery,
} from "@mui/material"
import { useTheme } from "@mui/material/styles"
import MenuIcon from "@mui/icons-material/Menu"

import WebLogo from "../../images/web-logo.svg"

import { StyledNavElement } from "./Navigation.styled"

import {usePageData} from "../../hooks/usePageData"
import {useSiteData} from "../../hooks/useSiteData"

// import { useMenuQuery } from "../../hooks/useMenuQuery"

const Navigation = ({
  yAxisAbout,
  yAxisCeo,
  yAxisAdvantages,
  yAxisChapters,
  yAxisContact,
  yAxisHome,
  yAxisJoin,
  yAxisEvents,
  yAxisPhilosophy,
  menuLinks,
}) => {
  // const {
  //   wpMenu: {
  //     menuItems: { nodes: navElements },
  //   },
  // } = useMenuQuery();
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"))
  const [yPos, setYPos] = useState(null)
  const [activeLink, setActiveLink] = useState("home")
  const [appBarWhite, setAppBarWhite] = useState(false)
  const [drawerIsOpen, setDrawerIsOpen] = useState(false)
  // const isScrolling = useScrollTrigger

  const openDrawer = () => {
    setDrawerIsOpen(true)
  }

  const handleScroll = useCallback(() => {
    const yAxis = window.pageYOffset
    setYPos(yAxis)
  }, [])

  useEffect(() => {
    // Update the document title using the browser API
    window.addEventListener("scroll", handleScroll)
    return () => {
      if (typeof window !== `undefined`) {
        window.removeEventListener("scroll", handleScroll)
      }
    }
  }, [handleScroll])

  useEffect(() => {
    if (yPos < yAxisAbout) {
      setActiveLink("home")
    }
    if (yPos > yAxisAbout - 150 && yPos < yAxisCeo) {
      setActiveLink("about")
    }
    if (yPos > yAxisCeo - 150 && yPos < yAxisAdvantages) {
      setActiveLink("ceo")
    }
    if (yPos > yAxisAdvantages - 150 && yPos < yAxisChapters) {
      setActiveLink("advantages")
    }
    if (yPos > yAxisChapters - 150 && yPos < yAxisJoin) {
      setActiveLink("chapters")
    }
    if (yPos > yAxisJoin - 150 && yPos < yAxisEvents) {
      setActiveLink("join")
    }
    if (yPos > yAxisEvents - 150 && yPos < yAxisPhilosophy) {
      setActiveLink("events")
    }
    if (yPos > yAxisPhilosophy - 150 && yPos < yAxisContact) {
      setActiveLink("philosophy")
    }
    if (yPos + 235 >= yAxisContact) {
      setActiveLink("contact")
    }
  }, [
    yAxisAbout,
    yAxisCeo,
    yAxisAdvantages,
    yAxisChapters,
    yAxisHome,
    yAxisJoin,
    yAxisEvents,
    yAxisPhilosophy,
    yAxisContact,
    yPos,
  ])

  useEffect(() => {
    yPos > 10 ? setAppBarWhite(true) : setAppBarWhite(false)
  }, [yPos])

  return (
    <>
      <AppBar
        position="fixed"
        color="transparent"
        elevation={0}
        sx={{
          backgroundColor: appBarWhite ? "white" : "initial",
          transition: "all 500ms",
        }}
      >
        <Container disableGutters>
          <Toolbar>
            <Box
              sx={{
                flexGrow: {
                  xs: 1,
                  md: 3,
                },
                display: "flex",
                justifyContent: {
                  xs: "flex-start",
                  md: "flex-start",
                },
              }}
            >
              <Scrollchor to="#home">
                <Box
                  component="img"
                  src={useSiteData().siteLogo.localFile.publicURL}
                  alt="logo preferred networking"
                  sx={{ py: 2, width: isSmall ? rem(150) : rem(260) }}
                />
              </Scrollchor>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: {
                  xs: "none",
                  sm: "none",
                  md: "block",
                  lg: "block",
                  xl: "block",
                },
              }}
            >
              <Box>
                <Box display="flex" justifyContent="space-between">
                  {usePageData().wpMenu.menuItems.nodes.map(({ id, label, url }) => (
                    <StyledNavElement
                      to={url}
                      key={id}
                      animate={{ duration: 1000, easing: easeOutQuad }}
                      isactivelink={activeLink === url.slice(1)}
                    >
                      {label}
                    </StyledNavElement>
                  ))}
                </Box>
                {/* <SocialLinks /> */}
              </Box>
            </Box>
            <Box
              sx={{
                display: {
                  xs: "flex",
                  sm: "flex",
                  md: "none",
                  lg: "none",
                },
              }}
            >
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={openDrawer}
              >
                <MenuIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer
        anchor="right"
        open={drawerIsOpen}
        onClose={() => setDrawerIsOpen(false)}
      >
        <List>
          {usePageData().wpMenu.menuItems.nodes.map(({ id, label, url }) => (
            <ListItem key={id}>
              <StyledNavElement
                to={url}
                animate={{ duration: 1000, easing: easeOutQuad }}
                isactivelink={activeLink === url.slice(1)}
                beforeAnimate={() => setDrawerIsOpen(false)}
              >
                {label}
              </StyledNavElement>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </>
  )
}

Navigation.propTypes = {
  children: PropTypes.node,
}

export default Navigation
