import React from "react"
import PropTypes from "prop-types"
import { Box, IconButton } from "@mui/material"
import FacebookIcon from "@mui/icons-material/Facebook"
import InstagramIcon from "@mui/icons-material/Instagram"

import LinkedInIcon from "@mui/icons-material/LinkedIn"

import {useSiteData} from "../../hooks/useSiteData"

const SocialLinks = ({ white, children }) => {
  return (
    <Box display="flex">
      <IconButton
        href={useSiteData().facebook}
        target="_blank"
        rel="noopener"
      >
        <FacebookIcon sx={{ color: white ? "white" : "initial" }} />
      </IconButton>
      <IconButton
        href={useSiteData().instagram}
        target="_blank"
        rel="noopener"
      >
        <InstagramIcon sx={{ color: white ? "white" : "initial" }} />
      </IconButton>
      {/* <IconButton target="_blank"
        rel="noopener">
        <TwitterIcon sx={{ color: white ? "white" : "initial" }} />
      </IconButton> */}
      <IconButton
        href={useSiteData().linkedin}
        target="_blank"
        rel="noopener"
      >
        <LinkedInIcon sx={{ color: white ? "white" : "initial" }} />
      </IconButton>
    </Box>
  )
}

SocialLinks.propTypes = {
  children: PropTypes.node,
}

export default SocialLinks
