import React from "react"
import PropTypes from "prop-types"
import { graphql, StaticQuery } from "gatsby"

import { StyledLayout } from "./Layout.styled"
import { Navigation } from "../Navigation"
import { Footer } from "../Footer"

const Layout = ({
  yAxisAbout,
  yAxisCeo,
  yAxisAdvantages,
  yAxisChapters,
  yAxisContact,
  yAxisHome,
  yAxisJoin,
  yAxisEvents,
  yAxisPhilosophy,
  children,
}) => {
  return (
    <StaticQuery
      query={graphql`
        query SiteMenuLinks {
          site {
            siteMetadata {
              menuLinks {
                id
                isActiveLink
                label
                scrollto
              }
            }
          }
        }
      `}
      render={data => (
        <StyledLayout>
          <Navigation
            menuLinks={data.site.siteMetadata.menuLinks}
            yAxisHome={yAxisHome}
            yAxisAbout={yAxisAbout}
            yAxisCeo={yAxisCeo}
            yAxisAdvantages={yAxisAdvantages}
            yAxisChapters={yAxisChapters}
            yAxisJoin={yAxisJoin}
            yAxisEvents={yAxisEvents}
            yAxisPhilosophy={yAxisPhilosophy}
            yAxisContact={yAxisContact}
          />
          {children}
          <Footer />
        </StyledLayout>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node,
}

export default Layout
