import { useStaticQuery, graphql } from "gatsby"

export const useSiteData = () => {
  const data = useStaticQuery(graphql`
    query SiteSettingsQuery {
        wpPage(databaseId: {eq: 332}) {
            siteSettings {
              address
              copyrightText
              facebook
              email
              fieldGroupName
              footerBackground {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                        width: 1280
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
              instagram
              linkedin
              phone
              siteLogo {
                localFile {
                    publicURL
                }
              }
              siteLogo2 {
                localFile {
                    publicURL
                }
              }
            }
        }
    }
  `)
  return data.wpPage.siteSettings
}
