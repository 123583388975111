import { useStaticQuery, graphql } from "gatsby"

export const usePageData = () => {
  const data = useStaticQuery(graphql`
    query HeroQuery {
      wpMenu(databaseId: {eq: 111}) {
        menuItems {
          nodes {
            label
            id
            url
          }
        }
      }
      wpPage(databaseId: { eq: 15 }) {
        homepage {
          heroSubTitle
          heroTitle
          heroBackgroundImage {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 1000
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          aboutContent
          aboutImage {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 490
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          aboutSubTitle
          aboutTitle
          networkingImage1 {
            localFile {
              publicURL
            }
          }
          networkingImage2 {
            localFile {
              publicURL
            }
          }
          networkingImage3 {
            localFile {
              publicURL
            }
          }
          ceoSubTitle
          ceoTitle
          ceoContent
          ceoImage {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 295
                  height: 429
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          advantagesImages {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 494
                  height: 335
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          advantagesListLeft
          advantagesListRight
          advantagesSubTitle
          advantagesTitle
          chaptersContent
          chaptersSubTitle
          chaptersTitle
          chapters {
            copy
            image {
              localFile {
                publicURL
              }
            }
            title
            email
            subject
            members {
              businessCategory
              businessName
              city
              email
              facebook
              fieldGroupName
              instagram
              linkedin
              name
              phone
              state
              website
            }
          }
          joinCtaBackground {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 1280
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          joinCtaContent
          joinCtaSubTitle
          joinCtaTitle
          joinFooterContent
          joinSubTitle
          joinTitle
          eventSubTitle
          eventTitle
          events {
            buttonText
            caption
            email
            fieldGroupName
            icon
            title
            website
          }
          philosophyContent
          philosophyImage {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 494
                  height: 335
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          philosophySubTitle
          philosophyTitle
        }
      }
    }
  `)
  return data
}
